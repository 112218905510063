import Vue from 'vue'
import { format, formatDistance } from 'date-fns'
import { fr } from 'date-fns/locale'
Vue.filter('momentFormat', (value, format) => {
  return format(value, format, { locale: fr });
});
Vue.filter('momentFrom', (value,format) => {
  return formatDistance( value, new Date(), { addSuffix: true, locale: fr });
});

export default (ctx, inject) => {
  inject('moment', { format })
}
