export default ({ $axios }, inject) => {
  $axios.onRequest((config) => { // change api url before call
    let url = config.url
    switch (true) {
      case /\/api\//.test(url):
        url = url.replace(new RegExp('^/api/'), '/wp-json/wp/v2/')
        break
      case /\/api2\//.test(url):
        url = url.replace(new RegExp('^/api2/'), '/wp-json/idealuz/v1/')
        break
    }
    config.url = url
    return config
  })
}
