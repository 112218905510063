<template lang="html">
  <svg class="logoIdeaLeft" version="1.1" x="0px" y="0px" viewBox="0 0 256 137.046" preserveAspectRatio="xMaxYMax meet" xml:space="preserve">
    <g>
    	<path d="M0.706,24.771c0.273,0.106,0.565,0.097,0.825-0.026c0.268-0.12,0.452-0.325,0.549-0.604l2.269-5.837h11.115
    		l2.306,5.837c0.113,0.279,0.299,0.484,0.558,0.604c0.14,0.063,0.29,0.102,0.44,0.102c0.129,0,0.254-0.023,0.383-0.076
    		c0.278-0.103,0.479-0.286,0.606-0.553c0.128-0.265,0.131-0.539,0.029-0.825l-8.878-22.7c-0.104-0.25-0.275-0.429-0.503-0.535
    		C9.874-0.086,9.366,0.004,9.16,0.092c-0.215,0.093-0.306,0.245-0.334,0.35c-0.051,0.137-0.051,0.281,0,0.528L0.073,23.395
    		c-0.105,0.285-0.098,0.558,0.028,0.823C0.22,24.485,0.428,24.668,0.706,24.771 M5.198,16.175L9.91,4.112l4.751,12.063H5.198z"/>
    	<path d="M18.467,2.135h7.652v21.634c0,0.283,0.113,0.534,0.321,0.742c0.207,0.212,0.461,0.32,0.744,0.32
    		c0.281,0,0.542-0.111,0.75-0.32c0.211-0.208,0.313-0.458,0.313-0.742V2.135H35.9c0.283,0,0.541-0.104,0.748-0.308
    		c0.21-0.217,0.321-0.468,0.321-0.752s-0.111-0.539-0.321-0.744c-0.207-0.209-0.456-0.318-0.748-0.318H18.467
    		c-0.279,0-0.534,0.109-0.742,0.318c-0.207,0.208-0.32,0.464-0.32,0.744c0,0.284,0.113,0.535,0.32,0.752
    		C17.933,2.034,18.188,2.135,18.467,2.135"/>
    	<path d="M58.885,24.511c0.206-0.208,0.311-0.459,0.311-0.742c0-0.286-0.105-0.539-0.311-0.746
    		c-0.21-0.215-0.463-0.318-0.752-0.318H44.107v-8.092h11.528c0.286,0,0.534-0.103,0.749-0.313c0.208-0.21,0.32-0.459,0.32-0.745
    		c0-0.28-0.111-0.542-0.32-0.748c-0.206-0.208-0.463-0.315-0.749-0.315H44.107V2.135h14.025c0.289,0,0.535-0.101,0.752-0.31
    		c0.206-0.215,0.311-0.466,0.311-0.75c0-0.28-0.105-0.536-0.311-0.744c-0.21-0.211-0.463-0.318-0.752-0.318H42.656
    		c-0.297,0-0.505,0.071-0.633,0.221c-0.1,0.131-0.153,0.292-0.153,0.459c0,0.136,0.023,0.277,0.065,0.419
    		c0.03,0.084,0.044,0.164,0.044,0.233v22.424c0,0.274,0.092,0.52,0.261,0.725c0.188,0.219,0.44,0.337,0.73,0.337h15.161
    		C58.421,24.831,58.667,24.723,58.885,24.511"/>
    	<path d="M63.983,1.114v22.655c0,0.274,0.083,0.52,0.26,0.725c0.185,0.219,0.433,0.337,0.722,0.337h15.555
    		c0.273,0,0.535-0.111,0.746-0.32c0.205-0.208,0.316-0.459,0.316-0.742c0-0.286-0.111-0.544-0.316-0.744
    		c-0.21-0.217-0.463-0.32-0.746-0.32H66.113V1.114c0-0.276-0.108-0.527-0.292-0.739c-0.365-0.39-1.188-0.364-1.493-0.246
    		c-0.232,0.084-0.336,0.238-0.367,0.355c-0.052,0.135-0.052,0.277-0.007,0.42C63.977,0.974,63.983,1.041,63.983,1.114"/>
    	<path d="M86.486,1.114v22.655c0,0.285,0.104,0.534,0.311,0.742c0.207,0.212,0.461,0.32,0.751,0.32
    		c0.283,0,0.527-0.108,0.742-0.32c0.208-0.208,0.323-0.459,0.323-0.742V1.114c0-0.276-0.104-0.527-0.292-0.739
    		c-0.366-0.39-1.189-0.364-1.491-0.246c-0.24,0.084-0.337,0.238-0.367,0.355c-0.053,0.135-0.053,0.277-0.009,0.42
    		C86.469,0.974,86.486,1.041,86.486,1.114"/>
    	<path d="M94.657,1.345v22.424c0,0.274,0.092,0.52,0.267,0.725c0.178,0.219,0.423,0.337,0.72,0.337h15.164
    		c0.287,0,0.535-0.108,0.75-0.32c0.209-0.208,0.313-0.459,0.313-0.742c0-0.286-0.104-0.539-0.313-0.746
    		c-0.215-0.215-0.463-0.318-0.75-0.318H96.783v-8.092h11.531c0.283,0,0.54-0.103,0.748-0.313c0.205-0.21,0.319-0.459,0.319-0.745
    		c0-0.28-0.114-0.542-0.319-0.748c-0.208-0.208-0.465-0.315-0.748-0.315H96.783V2.137h14.025c0.287,0,0.535-0.103,0.75-0.31
    		c0.209-0.217,0.313-0.468,0.313-0.752c0-0.28-0.104-0.536-0.313-0.744c-0.215-0.209-0.463-0.318-0.75-0.318H95.342
    		c-0.307,0-0.506,0.071-0.634,0.223c-0.111,0.129-0.165,0.29-0.165,0.458c0,0.136,0.023,0.277,0.075,0.419
    		C94.64,1.196,94.657,1.276,94.657,1.345"/>
    	<path d="M116.694,1.075v22.694c0,0.283,0.114,0.534,0.32,0.742c0.21,0.212,0.463,0.32,0.744,0.32
    		c0.284,0,0.536-0.111,0.747-0.32c0.214-0.208,0.316-0.458,0.316-0.742v-4.85h3.926c0.783,0,1.547-0.069,2.292-0.207l6.045,5.776
    		c0.216,0.194,0.438,0.316,0.788,0.283c0.259-0.015,0.5-0.109,0.704-0.277c0.158-0.13,0.343-0.376,0.292-0.797
    		c-0.031-0.297-0.134-0.535-0.311-0.716l-5.179-5.028c1.556-0.684,2.828-1.703,3.802-3.033c1.076-1.451,1.612-3.21,1.612-5.242
    		c0-1.369-0.259-2.661-0.786-3.843c-0.529-1.184-1.257-2.217-2.165-3.073c-0.899-0.852-1.98-1.53-3.198-2.019
    		c-1.22-0.482-2.528-0.73-3.897-0.73h-5.061c-0.309,0-0.506,0.028-0.643,0.08c-0.236,0.09-0.335,0.242-0.367,0.35
    		c-0.046,0.137-0.046,0.281,0,0.424C116.687,0.931,116.694,1,116.694,1.075 M118.822,16.794V2.135h3.926
    		c1.087,0,2.122,0.196,3.08,0.578c0.959,0.385,1.808,0.913,2.521,1.574c0.707,0.661,1.277,1.464,1.698,2.383
    		c0.413,0.917,0.629,1.929,0.629,3.007c0,1.078-0.216,2.067-0.629,2.944c-0.41,0.881-0.982,1.637-1.688,2.245
    		c-0.707,0.609-1.556,1.09-2.515,1.42c-0.96,0.334-2.003,0.507-3.096,0.507H118.822z"/>
    	<path d="M82.86,40.123c-1.051,1.049-1.565,2.358-1.565,3.995v29.52c-1.835-2.083-4.095-4.058-6.714-5.881
    		c-2.855-1.988-6.132-3.663-9.748-4.991c-3.689-1.345-7.689-2.03-11.904-2.03c-5.146,0-9.986,0.998-14.373,2.968
    		c-4.387,1.956-8.227,4.67-11.414,8.067c-3.196,3.401-5.719,7.413-7.513,11.92c-1.791,4.51-2.707,9.392-2.707,14.501
    		c0,5.107,0.916,9.986,2.707,14.5c1.786,4.496,4.306,8.504,7.513,11.912c3.187,3.398,7.027,6.118,11.414,8.072
    		c4.387,1.974,9.228,2.97,14.373,2.97c3.792,0,7.495-0.548,10.991-1.636c2.865-0.88,5.621-2.104,8.455-3.749l0.311-0.216
    		c0.215-0.097,0.507-0.239,0.862-0.419c2.616-1.653,4.999-3.632,7.093-5.885l0.565-0.64c4.366-4.998,9.81-11.229,10.628-27.885
    		V43.823c0-1.401-0.544-2.686-1.563-3.7C88.293,38.133,84.829,38.133,82.86,40.123 M81.007,98.19c0,3.695-0.758,7.249-2.26,10.542
    		c-1.511,3.347-3.564,6.307-6.09,8.789c-2.538,2.486-5.548,4.463-8.978,5.88c-6.662,2.779-14.922,2.782-20.931,0.034
    		c-3.063-1.398-5.725-3.351-7.92-5.803c-2.196-2.459-3.917-5.389-5.115-8.721c-1.203-3.347-1.82-6.952-1.82-10.722
    		s0.617-7.38,1.82-10.728c1.191-3.313,2.908-6.241,5.124-8.718c2.173-2.447,4.834-4.397,7.912-5.801
    		c3.073-1.4,6.492-2.112,10.181-2.112c3.608,0,7.146,0.741,10.512,2.201c3.376,1.467,6.412,3.482,9.013,5.983
    		c2.575,2.489,4.679,5.447,6.229,8.795C80.224,91.108,81.007,94.602,81.007,98.19"/>
    	<path d="M165.32,71.39c-3.242-3.345-7.136-6.009-11.578-7.923c-8.496-3.654-19.764-3.744-29.087-0.265
    		c-4.67,1.75-8.826,4.272-12.365,7.502c-3.57,3.26-6.41,7.261-8.47,11.879c-2.062,4.635-3.101,9.884-3.101,15.607
    		c0,5.131,0.986,10.037,2.944,14.565c1.949,4.506,4.655,8.493,8.054,11.84c3.382,3.33,7.385,6.005,11.89,7.952
    		c2.606,1.123,5.338,1.931,8.16,2.411c0.527,0.219,2.513,0.323,3.124,0.323h28.393c1.341,0,2.492-0.521,3.422-1.551
    		c0.907-1.003,1.358-2.243,1.358-3.68c0-1.446-0.451-2.686-1.358-3.688c-0.517-0.573-1.101-0.988-1.749-1.245l0.237-0.196h-0.877
    		c-0.333-0.074-0.676-0.11-1.033-0.11h-29.809c-0.237,0-0.469,0.016-0.692,0.045l-0.097-0.02c-1.568-0.324-3.079-0.782-4.478-1.361
    		c-3.099-1.282-5.856-3.06-8.208-5.266c-2.335-2.205-4.289-4.856-5.808-7.885c-1.121-2.234-1.901-4.642-2.332-7.161h58.206
    		c1.708,0,3.102-0.53,4.143-1.562c1.018-1.018,1.56-2.302,1.56-3.701c0-5.215-0.935-10.153-2.785-14.677
    		C171.135,78.719,168.561,74.736,165.32,71.39 M164.793,92.918h-52.726c0.485-2.49,1.322-4.886,2.476-7.125
    		c1.547-3.003,3.593-5.653,6.073-7.873c2.477-2.219,5.362-4,8.582-5.303c6.248-2.507,14.061-2.507,20.019-0.01
    		c3.046,1.281,5.723,3.044,7.954,5.238c2.247,2.202,4.073,4.844,5.445,7.845C163.655,87.954,164.384,90.383,164.793,92.918"/>
    	<path d="M255.877,98.098c0.015-0.458-0.054-0.919-0.054-1.381c0-5.146-0.998-9.978-2.962-14.373
    		c-1.955-4.379-4.669-8.221-8.073-11.424c-3.401-3.182-7.408-5.707-11.914-7.505c-4.363-1.736-9.175-2.629-14.001-2.69
    		c-5.604-0.252-11.254,0.723-16.149,2.914c-3.188,1.426-6.088,3.255-8.659,5.455c-0.725,0.583-1.43,1.191-2.107,1.827
    		c-3.399,3.198-6.122,7.036-8.076,11.424c-1.973,4.396-2.96,9.228-2.96,14.373c0,1.741,0.114,3.458,0.345,5.149
    		c0.334,3.755,1.186,7.363,2.534,10.765c1.8,4.514,4.319,8.521,7.51,11.912c3.203,3.408,7.041,6.127,11.414,8.079
    		c4.4,1.967,9.234,2.963,14.374,2.963c0.805,0,1.615,0.04,2.409-0.008c0.598,0.043,1.206,0.013,1.833,0.043h29.025
    		c0.122,0.012,0.243,0.016,0.369,0.016c1.399,0,2.716-0.549,3.696-1.538c1.026-1.013,1.569-2.29,1.569-3.704v-29.33
    		C255.95,100.035,255.961,99.049,255.877,98.098 M227.135,124.825h-4.356h-5.065l-0.028,0.002c-3.397-0.075-6.765-0.889-9.869-2.294
    		c-3.339-1.51-6.289-3.56-8.772-6.098c-1.912-1.934-3.52-4.165-4.808-6.649c-0.121-0.308-0.239-0.618-0.354-0.934
    		c-1.206-3.354-1.816-6.959-1.816-10.722c0-3.764,0.61-7.369,1.816-10.723c1.196-3.327,2.923-6.262,5.115-8.726
    		c0.199-0.22,0.403-0.438,0.607-0.647c2.322-1.924,5.019-3.451,8.041-4.536c3.354-1.208,6.959-1.818,10.721-1.818
    		s7.369,0.61,10.726,1.818c0.108,0.039,0.217,0.08,0.328,0.12c2.784,1.348,5.269,3.084,7.403,5.18
    		c2.527,2.486,4.586,5.44,6.099,8.778c1.5,3.313,2.292,6.856,2.292,10.553c0,0.412,0.014,0.756,0.017,1.196v2.359v5.169v0.315
    		v13.185v0.149v2.246v2.074h-4.272h-0.192h-12.769H227.135z"/>
    	<g>
    		<path d="M6.054,45.45C2.714,45.45,0,48.166,0,51.5c0,3.339,2.713,6.053,6.053,6.053
    			c3.332,0,6.046-2.714,6.046-6.053C12.1,48.166,9.385,45.45,6.054,45.45"/>
    		<path d="M11.882,65.941c-0.165-1.026-0.621-1.975-1.352-2.781c-0.972-1.057-2.315-1.604-4.002-1.635
    			c-1.646,0.046-2.959,0.594-3.914,1.63c-0.731,0.809-1.189,1.76-1.354,2.789c-0.044,0.278-0.067,0.562-0.067,0.85v63.435
    			c0,1.398,0.544,2.684,1.569,3.702c1.02,1.026,2.298,1.563,3.704,1.563c0.023,0,0.047-0.003,0.07-0.003
    			c0.046,0,0.092,0.003,0.142,0.003c1.404,0,2.683-0.535,3.701-1.562c1.025-1.02,1.569-2.305,1.569-3.703V66.796
    			C11.949,66.506,11.927,66.222,11.882,65.941"/>
    	</g>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
