<template lang="html">
  <svg class="logoIdea" version="1.1" x="0px" y="0px" viewBox="0 0 250 100" preserveAspectRatio="xMaxYMax meet" xml:space="preserve">
  	<g>
  		<path :fill="fill"  d="M85.082,8.616c-0.923,0.924-1.56,2.076-1.56,3.516v25.983c-1-1.833-3.422-3.57-5.726-5.177
  			c-2.514-1.749-5.307-3.223-8.489-4.392c-3.246-1.184-6.722-1.787-10.434-1.787c-4.529,0-8.766,0.877-12.627,2.612
  			c-3.862,1.721-7.23,4.11-10.036,7.101c-2.813,2.994-5.028,6.526-6.606,10.492c-1.577,3.97-2.379,8.266-2.379,12.763
  			s0.806,8.79,2.383,12.764c1.572,3.958,3.791,7.486,6.613,10.483c2.806,2.991,6.185,5.388,10.047,7.108
  			c3.861,1.736,8.122,2.612,12.651,2.612c3.339,0,6.599-0.483,9.676-1.439c2.52-0.772,4.947-1.852,7.441-3.3l0.274-0.189
  			c0.189-0.086,0.445-0.21,0.759-0.369c2.304-1.456,4.398-3.195,6.243-5.181l0.677-0.561c3.843-4.4,8.532-9.886,9.532-24.547V11.874
  			c0-1.233-0.749-2.363-1.646-3.257C90.136,6.866,86.815,6.866,85.082,8.616z M81.646,69.009c-1.329,2.946-3.136,5.552-5.361,7.736
  			c-2.232,2.186-4.883,3.927-7.899,5.174c-5.867,2.446-13.137,2.451-18.426,0.03c-2.696-1.23-5.038-2.949-6.972-5.106
  			c-1.935-2.166-3.448-4.745-4.503-7.677c-1.059-2.945-1.601-6.119-1.601-9.438s0.542-6.497,1.601-9.442
  			c1.048-2.916,2.561-5.494,4.511-7.674c1.912-2.153,4.255-3.872,6.964-5.105c2.705-1.233,5.714-1.859,8.96-1.859
  			c3.176,0,6.292,0.654,9.255,1.938c2.971,1.29,5.644,3.064,7.933,5.265c2.266,2.192,4.117,4.795,5.484,7.74
  			c1.355,2.903,2.045,5.98,2.045,9.137C83.637,62.98,82.969,66.11,81.646,69.009z"/>
  		<path :fill="fill"  d="M157.851,36.139c-2.854-2.944-6.282-5.289-10.191-6.974c-7.479-3.216-17.396-3.294-25.604-0.233
  			c-4.111,1.54-7.77,3.759-10.885,6.604c-3.143,2.868-5.641,6.389-7.455,10.453c-1.814,4.083-2.729,8.702-2.729,13.739
  			c0,4.517,0.869,8.836,2.59,12.821c1.716,3.968,4.098,7.476,7.09,10.421c2.977,2.933,6.499,5.288,10.466,7.002
  			c2.294,0.99,4.697,1.827,7.182,2.251c0.466,0.191,2.212,0.416,2.75,0.416h24.993c1.178,0,2.191-0.59,3.011-1.496
  			c0.798-0.883,1.196-2.04,1.196-3.305c0-1.271-0.398-2.396-1.196-3.279c-0.454-0.505-0.969-0.695-1.539-0.921l0.209,0.001h-0.772
  			c-0.292,0-0.595,0-0.908,0h-26.239c-0.208,0-0.413-0.225-0.609-0.197l-0.085-0.138c-1.381-0.287-2.711-0.749-3.941-1.259
  			c-2.729-1.131-5.156-2.724-7.226-4.664c-2.054-1.94-3.775-4.524-5.111-7.19c-0.987-1.967-1.675-3.552-2.054-6.552h51.234
  			c1.504,0,2.73-0.232,3.646-1.141c0.896-0.896,1.374-1.909,1.374-3.143c0-4.589-0.822-8.876-2.452-12.859
  			C162.969,42.532,160.703,39.084,157.851,36.139z M110.976,54.64c0.43-2,1.163-4.077,2.179-6.048
  			c1.361-2.644,3.161-4.864,5.346-6.817c2.181-1.955,4.72-3.465,7.555-4.611c5.499-2.206,12.376-2.178,17.62,0.018
  			c2.682,1.128,5.037,2.694,7.002,4.625c1.978,1.938,3.586,4.044,4.792,6.688c0.917,1.992,1.557,4.145,1.917,6.145H110.976z"/>
  		<path :fill="fill" d="M17.66,13.306c-2.94,0-5.328,2.389-5.328,5.325c0,2.938,2.388,5.328,5.328,5.328
  			c2.933,0,5.321-2.39,5.321-5.328C22.981,15.695,20.593,13.306,17.66,13.306z"/>
  		<path :fill="fill" d="M237.487,59.647c0.014-0.403-0.011-0.809-0.011-1.215c0-4.529-0.861-8.784-2.59-12.652
  			c-1.722-3.855-4.1-7.236-7.096-10.055c-2.993-2.802-6.517-5.025-10.483-6.608c-3.841-1.526-8.072-2.313-12.32-2.366
  			c-4.933-0.223-9.906,0.635-14.215,2.564c-2.806,1.254-5.356,2.864-7.621,4.8c-0.638,0.514-1.258,1.051-1.855,1.61
  			c-2.991,2.815-5.388,6.193-7.109,10.055c-1.734,3.868-2.604,8.123-2.604,12.652c0,1.531,0.101,3.043,0.303,4.531
  			c0.294,3.307,1.043,6.481,2.23,9.476c1.584,3.974,3.801,7.501,6.61,10.484c2.819,3,6.198,5.395,10.047,7.113
  			c3.874,1.73,8.129,2.607,12.652,2.607c0.709,0,1.423,0.017,2.121-0.026c0.527,0.039,1.061,0.021,1.614,0.021h25.547
  			c0.108,0,0.216,0.031,0.327,0.031c1.23,0,2.315-0.475,3.181-1.346c0.902-0.889,1.307-2.007,1.307-3.249V62.258
  			C237.522,61.354,237.562,60.484,237.487,59.647z M228.522,62.807v4.549v0.279v11.604v0.132v1.978v2.292h-4.094h-0.17H213.02h-0.759
  			h-3.835h-4.458l-0.025-0.23c-2.991-0.066-5.955-0.899-8.688-2.135c-2.938-1.33-5.536-3.193-7.721-5.429
  			c-1.683-1.702-3.098-3.693-4.231-5.88c-0.107-0.271-0.212-0.56-0.312-0.837c-1.062-2.952-1.599-6.135-1.599-9.446
  			s0.537-6.488,1.599-9.441c1.053-2.929,2.571-5.513,4.502-7.68c0.176-0.195,0.355-0.386,0.535-0.574
  			c2.044-1.693,4.416-3.036,7.077-3.992c2.951-1.063,6.126-1.601,9.438-1.601s6.487,0.538,9.439,1.601
  			c0.097,0.035,0.192,0.071,0.289,0.107c2.452,1.186,4.639,2.715,6.518,4.559c2.225,2.188,4.037,4.789,5.367,7.727
  			c1.321,2.915,2.183,6.036,2.183,9.288c0,0.361,0.184,0.666,0.184,1.054V62.807z"/>
  		<path :fill="fill" d="M21.602,28.895c-0.855-0.93-2.037-1.412-3.522-1.439c-1.45,0.041-2.605,0.523-3.445,1.436
  			c-0.644,0.712-0.977,1.548-1.122,2.454c-0.038,0.245,0.01,0.494,0.01,0.749v55.838c0,1.229,0.41,2.361,1.312,3.258
  			c0.897,0.902,1.988,1.375,3.224,1.375c0.021,0,0.025-0.002,0.045-0.002c0.042,0,0.073,0.004,0.115,0.004
  			c1.237,0,2.195-0.473,3.091-1.377c0.902-0.896,1.212-2.027,1.212-3.258V32.095c0-0.256,0.143-0.505,0.104-0.752
  			C22.482,30.439,22.244,29.604,21.602,28.895z"/>
  	</g>
  </svg>
</template>

<script>
export default {
  props: ['fill']
}
</script>
