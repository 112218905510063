<template>
  <v-app>
    <v-main>
      <nuxt/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: this.$config.appUrl + (this.$route.path || '').replace(/\/$|$/, '') // Canonical without slah
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.logo-idea {
  width: 30vw;
  min-width: 280px;
}
.v-toolbar__content {
  padding-bottom: 0 !important;
  .v-tab.v-tab--active {
    color: rgba(0,0,0,0.54) !important;
  }
}
.fade {
  &-enter-active, &-leave-active, &-leave-to {
    transition: .8s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &-enter, &-leave, &-leave-to {
    opacity: 0;
  }
}
.ksSlider.fullscreen .absolRow h1 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 3px;
  text-indent: 3px;
  font-family: Jaldi,sans-serif;
  margin-top: 5rem;
  text-align: center;
  color: #fff;
  span {
    font-size: 0.9rem;
    line-height: 1;
    letter-spacing: 2px;
    text-indent: 2px;
  }
}
</style>
