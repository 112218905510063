<template lang="html">
  <footer class="page-footer" v-if="$store.state.siteOptions">
    <v-container class="grid-list-lg">
      <v-layout row wrap>
        <v-flex xs12 md12 lg3 class="logo">
          <logo-idea></logo-idea>
        </v-flex>
        <v-flex xs12 md4 lg3 class="infoBlock">
          <span class="title text-uppercase d-block font-weight-bold">Contactez-nous</span>
          <a class="py-1" :href="'tel:'+ $store.state.siteOptions.company_phone" @click.capture="sendGaEvent('phone')">{{$store.state.siteOptions.company_phone}}</a>
          <a :href="'mailto:'+ $store.state.siteOptions.company_mail" @click.capture="sendGaEvent('mail')">{{$store.state.siteOptions.company_mail | mailFormat}}</a>
        </v-flex>
        <v-flex xs12 md4 lg3 class="infoBlock">
          <span class="title text-uppercase d-block font-weight-bold">Rencontrez-nous</span>
          <p>
            <span v-html="$store.state.siteOptions.company_address"></span><br>
            {{$store.state.siteOptions.company_zipcode}} {{$store.state.siteOptions.company_city}}
          </p>
        </v-flex>
        <v-flex xs12 md4 lg3 class="infoBlock">
          <span class="title text-uppercase d-block font-weight-bold">Suivez-nous</span>
          <ul class="pl-0">
            <li class="social">
              <a target="_blank" :aria-label="`Voir la page Facebook de l'atelier IDEA`" :href="$store.state.siteOptions.facebook_page_url">
                <v-icon  color="white" class="fa-brands fa-facebook"></v-icon>
              </a>
            </li>
            <li class="social">
              <a target="_blank" :aria-label="`Voir la page Instagram de l'atelier IDEA`" :href="$store.state.siteOptions.instagram_page_url">
                <v-icon color="white" class="fa-brands fa-instagram"></v-icon>
              </a>
            </li>
          </ul>
        </v-flex>
      </v-layout>
    </v-container>
    <div class="footer-copyright">
      <v-container class="grid-list-lg">
        <v-layout wrap>
          <v-flex>
            © {{copyright_date}} {{$store.state.siteOptions.company_name}}
            <router-link class="grey--text text--lighten-4 right" to="/mentions-legales">Mentions légales</router-link>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap align-center>
          <v-flex text-md-right text-xs-center caption>
            Solution web conçue et propulsée avec
            <v-icon :style="{lineHeight: 'inherit'}" small color="white" class="mx-1">icon-heart</v-icon>
            par Pirony @ <a href="https://studio-ks.fr" class="white--text" target="_blank">K's Studio</a>
          </v-flex>
        </v-layout> -->
        <v-layout wrap align-center>
          <v-flex text-md-right text-xs-center caption>
            Solution web conçue et propulsée avec
            <v-icon :style="{lineHeight: 'inherit'}" small color="white" class="mx-1">icon-heart</v-icon>
            par Pirony @ K's Studio
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </footer>
</template>

<script>
import logoIdea from '../svgs/logoIdeaLeft.vue'
export default {
  components:{
    'logo-idea': logoIdea
  },
  data(){
    return {
      copyright_date:''
    }
  },
  mounted(){
    this.copyright_date = this.$moment.format(new Date(),'yyyy');
  },
  methods:{
    sendGaEvent(eventType){
      if(process.BROWSER_BUILD){
        if(eventType === 'phone'){
          ga('send', 'event', 'PhoneClick', 'click', 'Click actions tracking');
        }
        if(eventType === 'mail'){
          ga('send', 'event', 'MailClick', 'click', 'Click actions tracking');
        }
      }
    }
  }
}
</script>
