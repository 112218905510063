import Vue from 'vue'
let tSFCount = 0;
const Velocity = window.Velocity = require('velocity-animate');
Vue.component('trans-stagg-fade', {
  functional: true,
  render: function (createElement, context) {
    var data = {
      props: {
        name: 'trans-stagg-fade',
        appear:true,
        tag: 'div',
        ts: 0
      },
      on: {
        beforeEnter: function (el) {
          el.style.opacity = 0
        },
        enter: function (el, done) {
          var delay = tSFCount * 100;
          tSFCount++;
          setTimeout(function () {
            Velocity.hook (el, "translateY", "100%")
            Velocity(
              el,
              { opacity: 1,translateY: '0%'},
              { complete: done }
            )
            if(tSFCount <= context.children.length) tSFCount = 0;
          }, delay)
        },
        leave: function (el, done) {
          var delay = el.dataset.index * 100
          setTimeout(function () {
            Velocity(
              el,
              { opacity: 0,translateY: '50%'},
              { duration: 250 },
              { complete: done }
            )
          }, 0)
        }
      }
    }
    return createElement('transition-group', data, context.children)
  }
})

Vue.component('mobile-nav-anim', {
  functional: true,
  render: function (createElement, context) {
    var data = {
      props: {
        name: 'mobile-nav-anim',
        appear:true,
        tag: 'div'
      },
      on: {
        beforeEnter: function(el){
          Velocity.hook (el, "translateX", "105%")
        },
        enter: function (el, done) {
          Velocity(
            el,
            { translateX: '0%'},
            { duration: 200 },
            { complete: done }
          );
        },
        leave: function (el, done) {
          Velocity(
            el,
            {translateX: '105%'},
            { duration: 300 },
            { complete: done }
          );
        }
      }
    }
    return createElement('transition', data, context.children)
  }
})

Vue.component('modal-anim', {
  functional: true,
  render: function (createElement, context) {
    var data = {
      props: {
        name: 'modal-anim',
        appear:true,
        tag: 'div'
      },
      on: {
        beforeEnter: function(el){
          Velocity.hook (el, "opacity", 0)
          el.style.display = 'block';
        },
        enter: function (el, done) {
          Velocity(
            el,
            { opacity: 1},
            { complete: done }
          );
        },
        leave: function (el, done) {
          Velocity(
            el,
            {opacity: 0},
            { complete: done }
          );
        },
        afterLeave(){
          // el.style.display = 'none';
        }
      }
    }
    return createElement('transition', data, context.children)
  }
})

Vue.component('trans-tribu', {
  functional: true,
  render: function (createElement, context) {
    var data = {
      class:'splitScreenWrapper row layout wrap ma-0',
      id: 'splitScreenWrapper',
      props: {
        name: 'trans-tribu',
        appear:true,
        tag: 'div',
        ts: 0
      },
      on: {
        beforeEnter: function (el) {
          el.style.opacity = 0
          el.style.maxWidth = 'none'
        },
        enter: function (el, done) {
          Velocity(
            el,
            { opacity: 1},
            { complete: done }
          )
        },
        leave: function (el, done) {
          if (process.BROWSER_BUILD && window.innerWidth >= 992) {
            el.style.maxWidth = el.offsetWidth/3+12 + "px";
          }
          Velocity(
            el,
            { opacity: 0},
            { complete: done }
          )
        }
      }
    }
    return createElement('transition-group', data, context.children)
  }
})


Vue.component('trans-loader-band', {
  functional: true,
  render: function (createElement, context) {
    var data = {
      class:'overlay bandWrapper',
      props: {
        name: 'trans-loader-band',
        appear:false,
        tag: 'div',
        ts: 0
      },
      on: {
        leave: function (el, done) {
          Velocity(
            el,
            {opacity: 0},
            { complete: done }
          )
        }
      }
    }
    return createElement('transition-group', data, context.children)
  }
})
