import orderBy from 'lodash/orderBy'
import unionBy from 'lodash/unionBy'
import union from 'lodash/union'
import uniqBy from 'lodash/uniqBy'

export const state = () => ({
  siteOptions: null,
  pagesList: [],
  pagesLoaded: false,
  pagesTotalCount:0,
  projectsList: [],
  projectsLoaded: false,
  projectsTotalCount: 0,
  typesList: [],
  typesLoaded: false,
  categoriesList: [],
  categoriesLoaded: false,
  categoriesTotalCount:0
})

export const getters = {
  pagesList: state => state.pagesList,
  siteOptions: state => state.siteOptions
}

export const actions = {
  async nuxtServerInit({commit}, { $axios }){
    const promises = [
      $axios.get('/api2/pages',{params:{per_page:100}}),
      $axios.get('/api2/options'),
      $axios.get('/api2/project-types',{params:{per_page:100}})
    ]
    const [pages, options, projtypes] = await Promise.all(promises)
    commit('addPages',{ pages })
    commit('receiveOptions',{ options })
    commit('receiveTypes',{ projtypes })
  },
  async listProjects ({ commit }, params) {
    commit('projectsLoading')
    try {
      const res = await this.$axios.get('/api2/realisations', { params })
      commit('receiveProjects', { projects:res })
      return res
    } catch (e) {
      console.log(e)
    }
  }
}

export const mutations = {

  receiveOptions (state, { options }) {
    state.siteOptions = options.data
  },

  loadPages (state) {
    state.pagesLoaded = false
  },
  addPages (state, {pages}) {
    state.pagesTotalCount = pages.headers['x-wp-total']
    state.pagesList = orderBy(union(pages.data, state.pagesList),'date','desc')
    state.pagesLoaded = true
  },
  projectsLoading (state) {
    state.projectsLoaded = false
  },
  receiveProjects (state, { projects }) {
    state.projectsTotalCount = projects.headers['x-wp-total']
    state.projectsList = orderBy(uniqBy([...projects.data, ...state.projectsList],'id'),'date','desc')
    state.projectsLoaded = true
  },
  typesLoaded (state, { }) {
    state.typesLoaded = false
  },
  receiveTypes (state, {projtypes}) {
    state.typesList = projtypes.data
    state.typesLoaded = true
  }
}
