import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b73d2674 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _5d86907a = () => interopDefault(import('../pages/error404.vue' /* webpackChunkName: "pages/error404" */))
const _2b1b5100 = () => interopDefault(import('../pages/greetings.vue' /* webpackChunkName: "pages/greetings" */))
const _0a4d7030 = () => interopDefault(import('../pages/la-tribu.vue' /* webpackChunkName: "pages/la-tribu" */))
const _15e961de = () => interopDefault(import('../pages/le-concept.vue' /* webpackChunkName: "pages/le-concept" */))
const _f817fc26 = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _7e65da8e = () => interopDefault(import('../pages/nos-realisations/index.vue' /* webpackChunkName: "pages/nos-realisations/index" */))
const _7caf3946 = () => interopDefault(import('../pages/nos-realisations/_slug.vue' /* webpackChunkName: "pages/nos-realisations/_slug" */))
const _6abdf3f8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _b73d2674,
    name: "contact"
  }, {
    path: "/error404",
    component: _5d86907a,
    name: "error404"
  }, {
    path: "/greetings",
    component: _2b1b5100,
    name: "greetings"
  }, {
    path: "/la-tribu",
    component: _0a4d7030,
    name: "la-tribu"
  }, {
    path: "/le-concept",
    component: _15e961de,
    name: "le-concept"
  }, {
    path: "/mentions-legales",
    component: _f817fc26,
    name: "mentions-legales"
  }, {
    path: "/nos-realisations",
    component: _7e65da8e,
    name: "nos-realisations"
  }, {
    path: "/nos-realisations/:slug?",
    component: _7caf3946,
    name: "nos-realisations-slug"
  }, {
    path: "/",
    component: _6abdf3f8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
