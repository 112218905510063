<template lang="html">
  <mobile-nav-anim>
    <nav class="mobileNav" :class="{'active':showMobileNav}" v-show="showMobileNav">
      <ul>
        <li class="logo">
          <logo-idea></logo-idea>
        </li>
        <router-link tag="li" :to="{name:item.page_name}" :key="index" v-if="showMobileNav" v-for="(item,index) in menu_links">
          <a href="#">{{item.title}}</a>
        </router-link>
        <li key="05" v-if="showMobileNav">
          <v-btn icon class="social" target="_blank" :href="siteOptions.facebook_page_url">
            <i class="fa-brands fa-facebook"></i>
          </v-btn>
          <v-btn icon class="social" target="_blank" :href="siteOptions.instagram_page_url">
            <i class="fa-brands fa-instagram"></i>
          </v-btn>
        <li>
          <a href="#" class="closeMobileNav center" @click.prevent="$emit('toggleMobileNav')">
            <i class="fa fa-close"></i>
          </a>
        </li>
      </ul>
    </nav>
  </mobile-nav-anim>
</template>

<script>
import {mapGetters} from 'vuex'
import logoIdea from '../svgs/logoIdea.vue'
export default {
  props:['showMobileNav','menu_links'],
  components:{
    'logo-idea': logoIdea
  },
  computed: {
    ...mapGetters({
      siteOptions: 'siteOptions'
    })
  },
  watch:{
    '$route.name'(){
      if(this.showMobileNav){
        this.$emit('toggleMobileNav');
      }
    }
  }
}
</script>

<style lang="css">
</style>
