<template lang="html">
  <v-app v-resize="setDims" v-scroll="onScroll">

    <v-carousel class="ksSlider fullscreen pos-relative" v-if="$route.name === 'index'" :interval="4000" :show-arrows="false" continuous cycle hide-delimiters :height="'100vh'">
      <div :style="{position: 'absolute', height: '100%', width: '100%', zIndex: 1}">
        <div class="overlay gradient"></div>
        <v-container fill-height class="text-xs-center" :style="{maxWidth: '640px', position: 'relative', zIndex: 2}">
          <v-layout row align-center>
            <v-flex>
              <h2 class="white--text text-center">ATELIER <br>
                <logo-idea :fill="'white'" class="logo-idea"></logo-idea>
              </h2>
            </v-flex>
          </v-layout>
          <div class="absolRow py-3" :style="{position: 'absolute',  width: '100%', zIndex: 2, bottom: 0, left: 0}">
            <div class="text-center">
              <h1 class="white--text mb-5">Design & travaux<span class="d-block mt-1">Saint Jean de Luz - Pays Basque</span></h1>
              <v-btn :aria-label="`En savoir plus sur L' atelier IDEA`" class="mx-auto" @click="$vuetify.goTo(windowSize.y)" icon><v-icon color="white" medium>icon-arrow-down-circle</v-icon></v-btn>
            </div>
          </div>
        </v-container>
      </div>
      <v-carousel-item
        v-for="(item,i) in currentPage.acf.homeslider"
        transition="fade"
        :key="i"
        :src="item.slide_img.sizes.xxlarge || item.slide_img.sizes.xlarge"/>
    </v-carousel>

    <v-toolbar color="white" height="92px" :style="{position: 'sticky', top: 0, width: '100%', left: 0, zIndex: 9}">
      <v-container pa-0 fill-height :style="{margin: '0 auto'}">
        <v-layout align-end justify-space-between v-show="$vuetify.breakpoint.smAndUp">
          <v-flex>
            <logo height="64px" class="mb-1"/>
          </v-flex>
          <v-spacer />
          <v-flex>
            <div class="d-flex">
              <v-tabs right color="transparent" :grow="false" active-class="lol" light>
                <v-tabs-slider color="black"></v-tabs-slider>
                <v-tab v-for="item in menu_links" :key="item.page_name" :to="item.page_name !== 'index' ? `/${item.page_name}` : '/'" nuxt light>
                  <span class="font-weight-regular">{{ item.title }}</span>
                </v-tab>
              </v-tabs>
              <v-btn :aria-label="`Voir la page Facebook de l'atelier IDEA`"  icon target="_blank" :href="$store.state.siteOptions.facebook_page_url">
                <v-icon v-text="'fa-brands fa-facebook'"/>
              </v-btn>
              <v-btn :aria-label="`Voir la page Instagram de l'atelier IDEA`"  icon target="_blank" :href="$store.state.siteOptions.instagram_page_url">
                <v-icon v-text="'fa-brands fa-instagram'"/>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
        <v-layout align-end justify-space-between v-show="!$vuetify.breakpoint.smAndUp">
          <v-flex>
            <logo height="64px"/>
          </v-flex>
          <v-spacer />
          <v-btn icon @click="showMobileNav = !showMobileNav"><v-icon v-text="'icon-menu'"/></v-btn>
        </v-layout>
      </v-container>
    </v-toolbar>

    <v-main>
      <nuxt/>
    </v-main>

    <client-only>
      <mobile-nav :showMobileNav="showMobileNav" :menu_links="menu_links" @toggleMobileNav="showMobileNav = !showMobileNav"/>
    </client-only>

    <foo-ter/>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import fooTer from '@/components/footers/bigFooter.vue'
import logoIdea from '@/components/svgs/logoIdea'
import logo from '@/components/svgs/logoIdeaLeft'
import mobileNav from '@/components/navigation/mobileNav'
export default {
  components: {
    logo,
    logoIdea,
    fooTer,
    mobileNav
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: this.$config.appUrl + (this.$route.path || '').replace(/\/$|$/, '') // Canonical without slah
        }
      ]
    }
  },
  data: () => ({
    menu_links:[
      {
        title: 'Accueil',
        page_name: 'index'
      },
      {
        title: 'Nos réalisations',
        page_name: 'nos-realisations'
      },
      {
        title: 'Le concept',
        page_name: 'le-concept'
      },
      {
        title: 'L\' équipe',
        page_name: 'la-tribu'
      },
      {
        title: 'Contact',
        page_name: 'contact'
      }
    ],
    windowSize: {
      x: 0,
      y: 0
    },
    offsetTop: 0,
    showMobileNav: false
  }),
  mounted () {
    this.setDims()
  },
  computed: {
    ...mapGetters({
      pages: 'pagesList'
    }),
    currentPage () {
      return this.pages.find(p => p.slug === this.$route.name)
    }
  },
  methods: {
    setDims (e) {
      if (!process.client) return
      this.windowSize.x = window.innerWidth
      this.windowSize.y = window.innerHeight
    },
    onScroll () {
      if (!process.client) return
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    }
  }
}
</script>

<style lang="scss">
.logo-idea {
  width: 30vw;
  min-width: 280px;
}
.v-toolbar__content {
  padding-bottom: 0 !important;
  .v-tab.v-tab--active {
    color: rgba(0,0,0,0.54) !important;
  }
}
.fade {
  &-enter-active, &-leave-active, &-leave-to {
    transition: .8s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &-enter, &-leave, &-leave-to {
    opacity: 0;
  }
}
.ksSlider.fullscreen .absolRow h1 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 3px;
  text-indent: 3px;
  font-family: Jaldi,sans-serif;
  margin-top: 5rem;
  text-align: center;
  color: #fff;
  span {
    font-size: 0.9rem;
    line-height: 1;
    letter-spacing: 2px;
    text-indent: 2px;
  }
}
</style>
