import Vue from 'vue'
if (process.client) {
  const Masonry = require('masonry-layout');
  let projectsMasonry;
  Vue.directive('masonry',{
    bind(el,binding,vNode){
      projectsMasonry = new Masonry( el, {
         itemSelector: vNode.data.attrs['data-item-selector'],
         transitionDuration: vNode.data.attrs['data-transition-duration']
      })
    },
    componentUpdated(){
       projectsMasonry.layout();
    },
    unbind(){
       projectsMasonry.destroy();
    }
  });

  Vue.directive('scroll-to-el', {
    bind(el,binding){
      $(el).on('click', function(){
        let offset = $(binding.value).offset().top - 64;
        $('html,body').velocity("scroll", { duration: 400, offset })
      })
    }
  });
}
Vue.directive('tooltip', {
  inserted(el,binding){
    Vue.nextTick(()=>{
      $(el).tooltip();
    });
  },
  unbind(el,binding){
    $(el).tooltip('remove');
  }
});


Vue.directive('modal', {
  inserted(el,binding){
      $(el).modal();
  }
});

Vue.directive('mat-pushpin', {
  update(el,binding){
    Vue.nextTick(()=>{
      $(el).pushpin({
        top: binding.value.top,
        offset:binding.value.offset
      });
    })
  }
})
